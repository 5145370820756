<template>
  <div class="row">
    <div class="col-md-6">
        <card class="horizontal-form" title="Horizontal Form">

          <div slot="header">
              <h4 class="card-title">Familias</h4>
              <p class="card-category">Lista, Edición y Borrado de fichas</p>
          </div>
          <div class="table-responsive table-full-width">
            <el-table :data="tableData" :row-class-name="tableRowClassName">
                <el-table-column label="Nombre" property="nombre" width="250"></el-table-column>
                <el-table-column label="Color" property="color" width="70"></el-table-column>
                <el-table-column label="Acciones">
                  <template slot-scope="props">
                      <a class="btn-neutral btn-simple btn-link btn-lg2" v-tooltip.top-center="'Edita'" @click="handleEdit(props.$index, props.row)">
                        <i class="fa fa-edit"></i>
                      </a>
                      <a class="btn-danger btn-simple btn-link btn-lg2" v-tooltip.top-center="'Borra'" @click="handleDelete(props.$index, props.row)">
                        <i class="fa fa-times"></i>
                      </a>
                  </template>
                </el-table-column>
            </el-table>
          </div>

        </card>
    </div> <!-- end card -->
    <div class="col-md-6">
      <card class="stacked-form" title="Ficha de Familia">
        <template slot="header">
          <h4 class="card-title">
            Ficha de Familia
          </h4>
        </template>

        <div class="col-12">
          <fg-input label="id"
            disabled=""
            inputClasses="col-md-3 col-3"
            placeholder="Id"
            v-model="familia.id">
          </fg-input>
          <fg-input label="Nombre"
            inputClasses="col-md-12 col-12"
            placeholder="Nombre"
            v-model="familia.nombre">
          </fg-input>
          <span style="color: gray; font-size: small;">COLOR</span><br>
          <el-select ref="taskTable" size="large" placeholder="Elige color" v-model="familia.color">
            <el-option v-for="color in colores" :value="color.value" :label="color.label" :key="color.value" :class="color.color">
            </el-option>
          </el-select>
        </div>
        <br>

        <l-button type="primary" v-if="(familia.id!==undefined) || (familia.id==undefined && familia.nombre!==undefined)" @click="limpia" style="margin-left: 10px;">
          Limpia
        </l-button>
        <l-button type="primary" v-if="familia.id!==undefined" @click="modifica" style="margin-left: 10px;">
          Modifica
        </l-button>
        <l-button type="primary" v-if="familia.id==undefined && familia.nombre!==undefined" @click="alta" style="margin-left: 10px;">
          Alta
        </l-button>


      </card>
  </div> <!-- end card -->


  </div>
</template>
<script>
  import Swal from 'sweetalert2'
  import 'sweetalert2/dist/sweetalert2.css'
  import { Checkbox, Radio } from 'src/components/index'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import axios from "axios";
  import crypt from "crypto-js";
  import AES from "crypto-js/aes";

  export default {
    components: {
      Checkbox,
      Radio,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data () {
      return {
        tableData: [],
        familia: {nombre:'',color:'1'},
        colores: [{value: '1', label: '', color: 'bc1'},
        {value: '2', label: '', color: 'bc2'},
        {value: '3', label: '', color: 'bc3'},
        {value: '4', label: '', color: 'bc4'},
        {value: '5', label: '', color: 'bc5'},
        {value: '6', label: '', color: 'bc6'},
        {value: '7', label: '', color: 'bc7'},
        {value: '8', label: '', color: 'bc8'},
        {value: '9', label: '', color: 'bc9'},
        {value: '10', label: '', color: 'bc10'},
        {value: '11', label: '', color: 'bc11'},
        {value: '12', label: '', color: 'bc12'},
        {value: '13', label: '', color: 'bc13'},
        {value: '14', label: '', color: 'bc14'},
        {value: '15', label: '', color: 'bc15'},
        {value: '16', label: '', color: 'bc16'},
        {value: '17', label: '', color: 'bc17'},
        {value: '18', label: '', color: 'bc18'},
        {value: '19', label: '', color: 'bc19'},
        {value: '20', label: '', color: 'bc20'},
        {value: '21', label: '', color: 'bc21'}]
      }
    },
    watch: {
      'familia.color': function (val) {
        const refsElInput = this.$refs.taskTable.$el.querySelector(".el-input--suffix .el-input__inner")
        switch (val) {
          case "1":
            refsElInput.style.background = "#F08080"
            break;
          case "2":
            refsElInput.style.background = "#FFA07A"
            break;
          case "3":
            refsElInput.style.background = "#FFB6C1"
            break;
          case "4":
            refsElInput.style.background = "#FFA500"
            break;
          case "5":
            refsElInput.style.background = "#FFFACD"
            break;
          case "6":
            refsElInput.style.background = "#FFE4B5"
            break;
          case "7":
            refsElInput.style.background = "#F0E68C"
            break;
          case "8":
            refsElInput.style.background = "#E6E6FA"
            break;
          case "9":
            refsElInput.style.background = "#DDA0DD"
            break;
          case "10":
            refsElInput.style.background = "#66CDAA"
            break;
          case "11":
            refsElInput.style.background = "#3CB371"
            break;
          case "12":
            refsElInput.style.background = "#AFEEEE"
            break;
          case "13":
            refsElInput.style.background = "#B0C4DE"
            break;
          case "14":
            refsElInput.style.background = "#6495ED"
            break;
          case "15":
            refsElInput.style.background = "#FFEBCD"
            break;
          case "16":
            refsElInput.style.background = "#D2B48C"
            break;
          case "17":
            refsElInput.style.background = "#F0F8FF"
            break;
          case "18":
            refsElInput.style.background = "#FAEBD7"
            break;
          case "19":
            refsElInput.style.background = "#FAF0E6"
            break;
          case "20":
            refsElInput.style.background = "#FFF0F5"
            break;
          case "21":
            refsElInput.style.background = "#FFE4E1"
            break;
        }
      }
    },
    computed:{



    },
    mounted() {
      this.cargalista()
    },
    methods: {
      cambiaColor() {
        console.log("color: ",this.familia.color)
      },
      cargalista() {
        var mijson = {
          funcion: "lista",
          tabla: "familia",
          campos: "*",
          where: ""
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://capravps.erpgestion.es:443', params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          this.tableData = resp
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },
      tableRowClassName (row) {
        console.log(row.row.color)
        var micolor = this.colores[row.row.color-1].color
        console.log(micolor)
        return micolor
      },
      handleEdit (index, row) {
        this.familia = row
      },
      handleDelete (index, row) {
        this.familia = row
        var borrproducto = this.familia.nombre
        var self = this
        Swal.fire({
          title: 'Estás seguro?',
          text: 'Realmente quieres borrar la familia: '+borrproducto,
          icon: 'question',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Si, borrarlo!',
          cancelButtonText: 'Cancelar',
          buttonsStyling: false
        }).then(function (valor) {
          if (valor.isDismissed == true) {
            console.log("cancelaron")
          } else {
            self.baja()
            Swal.fire({
              title: '¡Atención!',
              text: '¡Ficha de familia '+borrproducto+' eliminada!',
              timer: 1500,
              showConfirmButton: false
            })
          }
        })
      },
      limpia() {
        this.familia={nombre:'',color:'1'}
      },
      modifica() {
        var mijson = {
          funcion: "modifica",
          tabla: "familia",
          campos: "nombre, color",
          valores: "'"+this.familia.nombre+"', '"+this.familia.color+"'",
          id: String(this.familia.id)
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://capravps.erpgestion.es:443', params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          console.log(resp)
          if (resp.resp=="OK") {
            this.cargalista()
          }
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },
      alta() {
        var mijson = {
          funcion: "alta",
          tabla: "familia",
          campos: "nombre, color",
          valores: "'"+this.familia.nombre+"', '"+this.familia.color+"'"
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://capravps.erpgestion.es:443', params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          console.log(resp)
          if (resp.resp=="OK") {
            this.cargalista()
          }
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },
      baja() {
        var mijson = {
          funcion: "baja",
          tabla: "familia",
          id: String(this.familia.id)
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://capravps.erpgestion.es:443', params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          console.log(resp)
          if (resp.resp=="OK") {
            this.familia={}
            this.cargalista()
          }
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      }
    }


  }
</script>
<style>
  .bc1 {
    background: #F08080;
  }
  .bc2 {
    background: #FFA07A;
  }
  .bc3 {
    background: #FFB6C1;
  }
  .bc4 {
    background: #FFA500;
  }
  .bc5 {
    background: #FFFACD;
  }
  .bc6 {
    background: #FFE4B5;
  }
  .bc7 {
    background: #F0E68C;
  }
  .bc8 {
    background: #E6E6FA;
  }
  .bc9 {
    background: #DDA0DD;
  }
  .bc10 {
    background: #66CDAA;
  }
  .bc11 {
    background: #3CB371;
  }
  .bc12 {
    background: #AFEEEE;
  }
  .bc13 {
    background: #B0C4DE;
  }
  .bc14 {
    background: #6495ED;
  }
  .bc15 {
    background: #FFEBCD;
  }
  .bc16 {
    background: #D2B48C;
  }
  .bc17 {
    background: #F0F8FF;
  }
  .bc18 {
    background: #FAEBD7;
  }
  .bc19 {
    background: #FAF0E6;
  }
  .bc20 {
    background: #FFF0F5;
  }
  .bc21 {
    background: #FFE4E1;
  }
  .el-table .bc1 {
    background: #F08080;
  }
  .el-table .bc2 {
    background: #FFA07A;
  }
  .el-table .bc3 {
    background: #FFB6C1;
  }
  .el-table .bc4 {
    background: #FFA500;
  }
  .el-table .bc5 {
    background: #FFFACD;
  }
  .el-table .bc6 {
    background: #FFE4B5;
  }
  .el-table .bc7 {
    background: #F0E68C;
  }
  .el-table .bc8 {
    background: #E6E6FA;
  }
  .el-table .bc9 {
    background: #DDA0DD;
  }
  .el-table .bc10 {
    background: #66CDAA;
  }
  .el-table .bc11 {
    background: #3CB371;
  }
  .el-table .bc12 {
    background: #AFEEEE;
  }
  .el-table .bc13 {
    background: #B0C4DE;
  }
  .el-table .bc14 {
    background: #6495ED;
  }
  .el-table .bc15 {
    background: #FFEBCD;
  }
  .el-table .bc16 {
    background: #D2B48C;
  }
  .el-table .bc17 {
    background: #F0F8FF;
  }
  .el-table .bc18 {
    background: #FAEBD7;
  }
  .el-table .bc19 {
    background: #FAF0E6;
  }
  .el-table .bc20 {
    background: #FFF0F5;
  }
  .el-table .bc21 {
    background: #FFE4E1;
  }

</style>
